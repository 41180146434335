import React, { useEffect, useState } from 'react';
import FolderCreation from './FolderCreation';
import FolderGrid from './FolderGrid';
import ConfirmationModal from '../../../components/ConfirmationModal';
import SearchCom from '../../../components/SearchCom';

const PhotoManagementCon = () => {
  const [folders, setFolders] = useState([]);
  const [deleteImageNames, setdeleteImageNames] = useState([]);

  const fetchData = async () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/gallery/get`)
    .then((response) => response.json())
    .then((data) => setFolders(data));
  }

  useEffect(() => {
    fetchData()
  }, []);

  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newFolder, setNewFolder] = useState({ name: '', date: '', images: [] });

  const handleCreateFolder = async() => {
    console.log(newFolder);

    if (newFolder.name && newFolder.date) {
      if (isEditing) {
        await handleEditGallery(newFolder);
        fetchData()
        setIsEditing(false);
      } else {
        await handleSaveGallery(newFolder);
        fetchData()
      }
      setNewFolder({ name: '', date: '', images: [] });
      setShowCreateFolder(false);
    }
  };

  const handleEditFolder = (folder) => {
    setSelectedFolder(folder);
    setNewFolder({ _id: folder._id, name: folder.name, date: folder.date, images: folder.images });
    setIsEditing(folder._id);
    setShowCreateFolder(true);
  };

  const resetForm = () => {
    setSelectedFolder(null)
    setNewFolder({
      name: '', 
      images: []
    });
  };

  const handleSaveGallery = async (newFolder) => {
    console.log(newFolder);
    const formData_ = new FormData();

    formData_.append('name', newFolder.name); // Append name
    formData_.append('date', newFolder.date); // Append date

    newFolder.images.forEach((image) => {
        if (image.rawFiles instanceof File) {
            formData_.append('images', image.rawFiles); 
        }
    });    

    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/gallery/add`, {
      method: 'POST',
      body: formData_,
    });

    if (!response.ok) {
      throw new Error('Failed to add sponsorship');
    }

    const newSponsor = await response.json();
    console.log('After Adding', newSponsor);
  };

  const handleEditGallery = async (newFolder) => {
    console.log(newFolder);
    const formData_ = new FormData();
    console.log(deleteImageNames)
    formData_.append('name', newFolder.name); 
    formData_.append('date', newFolder.date); 
    
    if (deleteImageNames) {
      if (typeof deleteImageNames === 'string') {
          deleteImageNames = deleteImageNames.split(',').map((name) => name.trim());
      }
      console.log(deleteImageNames)
      formData_.append('deleteImageNames', JSON.stringify(deleteImageNames));
    }   

    newFolder.images.forEach((image) => {
        if (image.rawFiles instanceof File) {
            formData_.append('images', image.rawFiles);
        }
    }
    )
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/gallery/edit/${isEditing}`, {
      method: 'PUT',
      body: formData_,
    });
    if (!response.ok) {
      throw new Error('Failed to update sponsor');
    }
    const updatedSponsor = await response.json();
    console.log('After Updating', updatedSponsor);
  };

  const handleDeleteGallery = async (id) => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/gallery/delete/${id}`, {
      method: 'DELETE',
    });
    if (!response.ok) {
      throw new Error('Failed to delete sponsor');
    }
    const deletedSponsor = await response.json();
    console.log('After Deleting', deletedSponsor);
    fetchData()
  };


  return (
    <div className="min-h-screen max-h-screen w-full px-10 overflow-scroll">
      <div className="py-5 mx-auto flex items-center justify-between gap-4">
        <div className="w-[70%] flex gap-6">
          <SearchCom />
          <button
            onClick={() => { setShowCreateFolder(true); resetForm(); }}
            className="bg-gray-900 text-white px-4 py-2 rounded-full hover:bg-gray-800 transition-colors flex items-center gap-2"
          >
            Create Folder <span>+</span>
          </button>
        </div>
        <div>
          <img src="/assets/mask.svg" alt="" />
        </div>
      </div>
      <div className="bg-gray-50 rounded-md mt-6">
        {showCreateFolder && (
          <FolderCreation
            newFolder={newFolder}
            setNewFolder={setNewFolder}
            handleCreateFolder={handleCreateFolder}
            setShowCreateFolder={setShowCreateFolder}
            isEditing={isEditing}
            setSelectedFolder={setSelectedFolder}
            setShowDeleteConfirm={setShowDeleteConfirm}
            deleteImageNames={deleteImageNames}
            setdeleteImageNames={setdeleteImageNames}
          />
        )}
        {!showCreateFolder && (
          <FolderGrid
            folders={folders}
            setSelectedFolder={setSelectedFolder}
            setShowDeleteConfirm={setShowDeleteConfirm}
            onEdit={handleEditFolder}
          />
        )}
        <ConfirmationModal
          isOpen={showDeleteConfirm}
          onClose={() => setShowDeleteConfirm(false)}
          onConfirm={() => {
            console.log(selectedFolder._id);
            handleDeleteGallery(selectedFolder._id);
            setShowCreateFolder(false);
            setShowDeleteConfirm(false);
          }}
          message="Are you sure to delete this folder?"
        />
      </div>
    </div>
  );
};

export default PhotoManagementCon;
