import React, { useState, useEffect } from 'react';
import { 
  Filter, 
  ChevronDown,
  RefreshCcw,
  Search,
  Mail
} from 'lucide-react';

const MemberTable = ({ onViewMore }) => {
  const [members, setMembers] = useState([]);
  const [activeTab, setActiveTab] = useState('Current');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    membershipType: 'all',
    year: 'all'
  });

  // Get unique membership types and years
  const membershipTypes = ['all', ...new Set(members.map(m => m.membershipName))];
  const years = ['all', ...new Set(members.map(m => 
    new Date(m.createdAt).getFullYear()
  ))].sort((a, b) => b - a);

  useEffect(() => {
    fetchMembers();
    // Check for expiring memberships every hour
    const interval = setInterval(checkExpiringMemberships, 3600000);
    return () => clearInterval(interval);
  }, []);

  const sendExpiryNotification = async (member) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/notifications/send-expiry`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userId: member.userId,
          email: member.email,
          membershipName: member.membershipName,
          expiryDate: member.expiryDate
        })
      });
      
      if (!response.ok) {
        throw new Error('Failed to send notification');
      }

      // Update member record to mark notification as sent
      await fetch(`${process.env.REACT_APP_BASE_URL}/membership/update-notification/${member.userId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          notificationSent: true,
          notificationDate: new Date().toISOString()
        })
      });
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  const checkExpiringMemberships = async () => {
    const activeMembers = members.filter(m => 
      (m.validityStatus === 'Active' || m.status === 'Current') && 
      !m.notificationSent
    );

    for (const member of activeMembers) {
      const daysUntilExpiry = Math.ceil((new Date(member.expiryDate) - new Date()) / (1000 * 60 * 60 * 24));
      
      if (daysUntilExpiry === 1) {
        await sendExpiryNotification(member);
      }
    }
  };

  const fetchMembers = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/membership/getall`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch members');
      }
      
      const data = await response.json();
      const processedMembers = await Promise.all(data.map(async (member) => {
        const validityInfo = await checkValidity(member);
        return { 
          ...member, 
          ...validityInfo
        };
      }));
      
      setMembers(processedMembers);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const checkValidity = async (member) => {
    if (!member.createdAt) return { validityStatus: 'Unknown', daysRemaining: 0 };
    if (member.status === 'Pending') return { validityStatus: 'Pending', daysRemaining: 0 };

    const creationDate = new Date(member.createdAt);
    const today = new Date();
    const expiryDate = new Date(creationDate);
    expiryDate.setDate(creationDate.getDate() + 365);
    
    const daysDiff = Math.floor((expiryDate - today) / (1000 * 60 * 60 * 24));
    const isExpired = today >= expiryDate;

    // Only update expired status if it hasn't been marked as expired yet
    if (isExpired && member.status !== 'Expired' && !member.expiryProcessed) {
      try {
        await fetch(`${process.env.REACT_APP_BASE_URL}/membership/update-status/${member.userId}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            status: 'Expired',
            action: 'expire',
            expiryDate: expiryDate.toISOString(),
            expiryProcessed: true
          })
        });
      } catch (error) {
        console.error('Error updating expired status:', error);
      }
    }

    return {
      validityStatus: isExpired ? 'Expired' : 
                     member.status === 'Current' || member.status === 'Active' ? 'Active' : 
                     member.status,
      daysRemaining: isExpired ? Math.abs(daysDiff) : daysDiff,
      expiryDate: expiryDate
    };
  };

  const filterMembers = () => {
    return members.filter(member => {
      const tabFilter = 
        activeTab === 'Current' ? (member.validityStatus === 'Active' || member.status === 'Current') :
        activeTab === 'Pending' ? member.validityStatus === 'Pending' :
        activeTab === 'Expired' ? member.validityStatus === 'Expired' : true;

      if (!tabFilter) return false;

      const searchFilter = 
        !searchTerm || 
        member.firstname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        member.lastname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        member.userId?.toString().includes(searchTerm) ||
        member.requestId?.toString().includes(searchTerm);

      const typeFilter = 
        filters.membershipType === 'all' || 
        member.membershipName === filters.membershipType;

      const yearFilter = 
        filters.year === 'all' || 
        new Date(member.createdAt).getFullYear().toString() === filters.year;

      return searchFilter && typeFilter && yearFilter;
    });
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const getStatusStyle = (validityStatus) => {
    switch (validityStatus) {
      case 'Active':
      case 'Current':
        return 'bg-green-100 text-green-800';
      case 'Pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'Expired':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const FilterDropdown = ({ label, value, options, onChange }) => (
    <div className="flex flex-col gap-1">
      <label className="text-sm font-medium text-gray-600">{label}</label>
      <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="block w-full rounded-md border border-gray-300 p-2"
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option === 'all' ? `All ${label}s` : option}
          </option>
        ))}
      </select>
    </div>
  );

  if (loading) {
    return (
      <div className="bg-white rounded-lg p-6">
        <p className="text-center text-gray-500">Loading members...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white rounded-lg p-6">
        <p className="text-center text-red-500">Error: {error}</p>
        <button 
          onClick={fetchMembers}
          className="mt-4 mx-auto block bg-[rgb(40,45,70)] text-white px-4 py-2 rounded-lg hover:bg-[rgb(50,55,80)]"
        >
          <RefreshCcw className="w-4 h-4 inline mr-2" />
          Retry
        </button>
      </div>
    );
  }

  const filteredMembers = filterMembers();
  const currentCount = members.filter(m => m.validityStatus === 'Active' || m.status === 'Current').length;
  const pendingCount = members.filter(m => m.validityStatus === 'Pending').length;
  const expiredCount = members.filter(m => m.validityStatus === 'Expired').length;

  return (
    <div className="bg-white rounded-lg relative">
      {/* Fixed header section */}
      <div className="sticky top-0 z-50 bg-white border-b shadow-sm">
        {/* Status Tabs */}
       

        {/* Search and Filters */}
        <div className="px-6 py-2 bg-white">
          <div className="flex gap-4 items-center">
            <div className="flex-1 relative">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search members by name or ID..."
                className="w-full py-2 px-4 pl-10 rounded-full bg-white border border-gray-200"
              />
              <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
            </div>
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="bg-white text-[rgb(40,45,70)] px-4 py-2 rounded-lg hover:bg-gray-100 flex items-center gap-2"
            >
              <Filter className="w-4 h-4" />
              {showFilters ? 'Hide Filters' : 'Filters'}
              <ChevronDown className={`w-4 h-4 transform transition-transform duration-300 ${showFilters ? 'rotate-180' : ''}`} />
            </button>
          </div>

          {/* Filter Dropdown Section */}
          <div className={`
            transform transition-all duration-300 ease-in-out overflow-hidden
            ${showFilters ? 'max-h-40 mt-4' : 'max-h-0'}
          `}>
            <div className="grid grid-cols-2 gap-8">
              <FilterDropdown 
                label="Membership Type"
                value={filters.membershipType}
                options={membershipTypes}
                onChange={(value) => setFilters(prev => ({ ...prev, membershipType: value }))}
              />
              <FilterDropdown 
                label="Year"
                value={filters.year}
                options={years}
                onChange={(value) => setFilters(prev => ({ ...prev, year: value }))}
              />
            </div>
          </div>
        </div>
      </div>

      
       <div className="border-b">
          <div className="flex justify-center space-x-20 px-6 py-4">
            <button
              onClick={() => setActiveTab('Current')}
              className={`px-6 py-2 rounded-full transition-all font-medium ${
                activeTab === 'Current' 
                  ? 'bg-[rgb(40,45,70)] text-white' 
                  : 'text-gray-500 hover:bg-gray-100'
              }`}
            >
              Current ({currentCount})
            </button>
            <button
              onClick={() => setActiveTab('Pending')}
              className={`px-6 py-2 rounded-full transition-all font-medium ${
                activeTab === 'Pending' 
                  ? 'bg-[rgb(40,45,70)] text-white' 
                  : 'text-gray-500 hover:bg-gray-100'
              }`}
            >
              Pending ({pendingCount})
            </button>
            <button
              onClick={() => setActiveTab('Expired')}
              className={`px-6 py-2 rounded-full transition-all font-medium ${
                activeTab === 'Expired' 
                  ? 'bg-[rgb(40,45,70)] text-white' 
                  : 'text-gray-500 hover:bg-gray-100'
              }`}
            >
              Expired ({expiredCount})
            </button>
          </div>
        </div>
      {/* Scrollable table section */}
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">User Id</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Member Code</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Name</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Created Date</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Validity Status</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Plan Type</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {filteredMembers.length === 0 ? (
              <tr>
                <td colSpan="7" className="px-6 py-4 text-center text-gray-500">
                  No members found for the selected filters
                </td>
              </tr>
            ) : (
              filteredMembers.map((member) => (
                <tr key={member.userId} className="hover:bg-gray-50">
                  <td className="px-6 py-4 text-sm text-gray-900">{member.userId}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{member.requestId}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {member.firstname} {member.lastname}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {formatDate(member.createdAt)}
                  </td>
                  <td className="px-6 py-4 text-sm">
                    <span 
                      className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusStyle(member.validityStatus)}`}
                    >
                      {member.validityStatus}
                      {member.validityStatus === 'Active' && 
                        <span className="ml-1 text-xs">
                   
                        </span>
                      }
                    </span>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {member.membershipName}
                  </td>
                  <td className="px-6 py-4 text-sm">
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => onViewMore(member)}
                        className="bg-[rgb(40,45,70)] text-white px-4 py-1 rounded-full hover:bg-[rgb(50,55,80)]"
                      >
                        View More
                      </button>
                      {member.validityStatus === 'Active' && member.daysRemaining <= 30 && !member.notificationSent && (
                        <button
                          onClick={() => sendExpiryNotification(member)}
                          className="bg-yellow-500 text-white px-3 py-1 rounded-full hover:bg-yellow-600 flex items-center gap-1"
                        >
                          <Mail className="w-4 h-4" />
                          Send Reminder
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MemberTable;