import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider, useNavigate, } from "react-router-dom";
import BlogManagement from './admin/pages/BlogManagement/BlogManagement';
import TeamManagement from './admin/pages/TeamManagement/TeamManagement';
import SponsorshipManagement from './admin/pages/SponsorManagement/SponsorManagement';
import UserManagement from './admin/pages/UserManagement/UserManagement';
import NotFound from './Notfound';
import Eventmanagement from './admin/pages/EventManagement/eventmanagement';
import SupportDeskManager from './admin/pages/SupportDesk/SupportDeskManager';
import GalleryManagement from './admin/pages/GalleryManagement/GalleryManagement';
import StoryManagement from './admin/pages/StoryManagement/StoryManagement';
import Membershipmanagement from './admin/pages/MembershipManagement/Membershipmanagement';
import Login from "./admin/components/login.jsx"

function Home() {
    const navigate = useNavigate();
    useEffect(() => {
        navigate("/");
    }, []);

    return (
        <div>
        </div>
    )
}
  
const router = createBrowserRouter([
    {
        path: '/',
        element: <Login/>,
    },
    {
      path: "/admin/eventmanagement",
      element: <Eventmanagement />,
    },
    {
      path: "/admin/teammanagement",
      element: <TeamManagement />,
    },
    {
      path: "/admin/sponsorship",
      element: <SponsorshipManagement />,
    },
    {
      path: "/admin/usermanagement",
      element: <UserManagement />,
    },
    {
        path: "/admin/blogmanagement",
        element: <BlogManagement />,
    },
    {
        path: "/admin/supportdesk",
        element: <SupportDeskManager />,
    },
    {
        path: "/admin/gallery",
        element: <GalleryManagement />,
    },
    // {
    //     path: "/admin/storyboard",
    //     element: <StoryManagement />,
    // },
    {
        path: "/admin/membershipmanagement",
        element: <Membershipmanagement />,
    },
    {
        path: "*",
        element: <NotFound />,
    },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// reportWebVitals();