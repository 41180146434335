import React, { useEffect, useState } from 'react';
import SearchCom from '../../../components/SearchCom';
import AddEventModal from './EventActions';
import EventCard from './EventCard';

const EventCardGrid = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [eventData, setEventData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);
    
    const fetchData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/events/get`);
            const data = await response.json();
            setEventData(data);
        } catch (error) {
            console.error('Error fetching events:', error);
        }
    }

const handleSaveEvent = async (formData, images, eventId) => {
    try {
        const formData_ = new FormData();

        // Add basic event details
        formData_.append('name', formData.name);
        formData_.append('description', formData.description);
        formData_.append('eventLink', formData.eventLink); // Make sure eventLink is added
        formData_.append('location', formData.location);
        formData_.append('status', 'Active');

        // Add date and time details
        formData_.append('date', formData.date);
        formData_.append('time', formData.time);
        
        // Explicitly add endDate and endTime if they exist
        if (formData.endDate) {
            formData_.append('endDate', formData.endDate);
        }
        if (formData.endTime) {
            formData_.append('endTime', formData.endTime);
        }

        // Add images
        if (images && images.length > 0) {
            images.forEach((image) => {
                if (image instanceof File) {
                    formData_.append('images', image);
                }
            });
        }

        // Log FormData contents for debugging
        for (let [key, value] of formData_.entries()) {
            console.log(`${key}: ${value}`);
        }

        const url = eventId 
            ? `${process.env.REACT_APP_BASE_URL}/events/edit/${eventId}`
            : `${process.env.REACT_APP_BASE_URL}/events/add`;

        const response = await fetch(url, {
            method: eventId ? 'PUT' : 'POST',
            body: formData_
        });

        if (!response.ok) {
            throw new Error(`Failed to ${eventId ? 'update' : 'add'} event`);
        }

        await fetchData();
        setIsModalOpen(false);
        setSelectedEvent(null);

    } catch (error) {
        console.error('Error saving event:', error);
    }
};

    const handleManageEvent = (event) => {
        setSelectedEvent(event);
        setIsModalOpen(true);
    };

    return (
        <div className="bg-gray-100 min-h-screen max-h-screen w-full px-10 overflow-scroll">
            <div className="py-5 mx-auto flex items-center justify-between gap-4">
                <div className='w-[70%] flex gap-6'>
                    <SearchCom />
                    <button 
                        onClick={() => { 
                            setSelectedEvent(null);
                            setIsModalOpen(true); 
                        }} 
                        className="bg-gray-900 text-white px-4 py-2 rounded-full hover:bg-gray-800 transition-colors flex items-center gap-2"
                    >
                        ADD <span>+</span>
                    </button>
                </div>
                <div><img src="/assets/mask.svg" alt="" /></div>
            </div>

            <div className="mx-auto mb-6 mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {eventData.map((event) => (
                    <EventCard
                        key={event._id}
                        {...event}
                        onManage={() => handleManageEvent(event)}
                    />
                ))}
            </div>

            <AddEventModal
                isOpen={isModalOpen}
                onClose={() => { 
                    setIsModalOpen(false); 
                    setSelectedEvent(null); 
                }}
                eventData={selectedEvent}
                onSave={handleSaveEvent}
            />
        </div>
    );
};

export default EventCardGrid;