import React, { useEffect, useState } from 'react';
import { X, Calendar, Clock, MapPin, Upload, Link as LinkIcon } from 'lucide-react';

const AddEventModal = ({ isOpen, onClose, eventData, onSave }) => {
    const [images, setImages] = useState([null, null, null]);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        eventLink: '',
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        location: '',
    });
    const formatDateForInput = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return '';
        return date.toISOString().split('T')[0];
    };

    const formatTimeForInput = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return '';
        return date.toTimeString().slice(0, 5);
    };

 useEffect(() => {
        if (eventData) {
            setFormData({
                name: eventData.name || '',
                description: eventData.description || '',
                eventLink: eventData.eventLink || '',
                startDate: eventData.date ? new Date(eventData.date).toISOString().split('T')[0] : '',
                startTime: eventData.time || '',
                endDate: eventData.endDate ? new Date(eventData.endDate).toISOString().split('T')[0] : '',
                endTime: eventData.endTime || '',
                location: eventData.location || '',
            });
            
            const imageArray = Array.isArray(eventData.images) 
                ? eventData.images.map(img => img || null)
                : [null, null, null];
            setImages(imageArray);
        }
    }, [eventData]);

    const handleImageUpload = (index) => (event) => {
        const file = event.target.files[0];
        if (file) {
            const newImages = [...images];
            newImages[index] = file;
            setImages(newImages);
        }
    };

const handleSubmit = (e) => {
        e.preventDefault();
        
        // Create date objects
        const startDateTime = new Date(formData.startDate);
        const endDateTime = formData.endDate ? new Date(formData.endDate) : null;

        const formattedData = {
            name: formData.name,
            description: formData.description,
            eventLink: formData.eventLink,
            date: startDateTime.toISOString(),
            time: formData.startTime,
            endDate: endDateTime ? endDateTime.toISOString() : null,
            endTime: formData.endTime,
            location: formData.location,
            status: 'Active'
        };

        // Filter out null values from images array
        const filteredImages = images.filter(img => img !== null);
        onSave(formattedData, filteredImages, eventData?._id);
    
    
    // Reset form
    setFormData({
        name: '',
        description: '',
        eventLink: '',
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        location: '',
    });
    setImages([null, null, null]);
    onClose();
};


    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-xl px-14 w-[60%] relative h-[90%] overflow-scroll py-4">
                <button 
                    onClick={onClose} 
                    className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
                >
                    <X size={24} />
                </button>

                <form onSubmit={handleSubmit} className="space-y-6">
                    {/* Image Upload Section */}
                    <div className="space-y-2">
                        <label className="block text-sm font-medium">Add Image</label>
                        <div className="flex gap-4">
                            {[0, 1, 2].map((index) => (
                                <div
                                    key={index}
                                    className="w-32 h-32 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center cursor-pointer hover:border-gray-400 transition-colors"
                                >
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageUpload(index)}
                                        className="hidden"
                                        id={`image-${index}`}
                                    />
                                    <label htmlFor={`image-${index}`} className="w-full h-full flex items-center justify-center">
                                        {images[index] ? (
                                            images[index] instanceof File ? (
                                                <img
                                                    src={URL.createObjectURL(images[index])}
                                                    alt={`Image ${index + 1}`}
                                                    className="w-full h-full object-cover rounded-lg"
                                                />
                                            ) : (
                                                <img
                                                    src={`${images[index]}`}
                                                    alt={`Image ${index + 1}`}
                                                    className="w-full h-full object-cover rounded-lg"
                                                />
                                            )
                                        ) : (
                                            <div className="text-center">
                                                <Upload className="mx-auto text-gray-400 mb-2" size={24} />
                                                <span className="text-sm text-gray-500">Image {index + 1}</span>
                                            </div>
                                        )}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Form Fields */}
                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium mb-1">Name</label>
                            <input
                                type="text"
                                placeholder="Write an Event Name"
                                className="w-full px-3 py-2 border rounded-lg"
                                value={formData.name}
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                required
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1">Event Link</label>
                            <div className="relative">
                                <input
                                    type="url"
                                    placeholder="Enter event link"
                                    className="w-full px-3 py-2 border rounded-lg pl-10"
                                    value={formData.eventLink}
                                    onChange={(e) => setFormData({ ...formData, eventLink: e.target.value })}
                                />
                                <LinkIcon className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={16} />
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1">Description</label>
                            <textarea
                                placeholder="Write a description about the Event (Optional)"
                                className="w-full px-3 py-2 border rounded-lg"
                                rows="2"
                                value={formData.description}
                                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                            />
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium mb-1">Start Date</label>
                                <div className="relative">
                                    <input
                                        type="date"
                                        className="w-full px-3 py-2 border rounded-lg pl-10"
                                        value={formData.startDate}
                                        onChange={(e) => setFormData({ ...formData, startDate: e.target.value })}
                                        required
                                        min="2024-01-01"
                                        max="2030-12-31"
                                    />
                                    <Calendar className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={16} />
                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium mb-1">Start Time</label>
                                <div className="relative">
                                    <input
                                        type="time"
                                        className="w-full px-3 py-2 border rounded-lg pl-10"
                                        value={formData.startTime}
                                        onChange={(e) => setFormData({ ...formData, startTime: e.target.value })}
                                        required
                                    />
                                    <Clock className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={16} />
                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium mb-1">End Date (Optional)</label>
                                <div className="relative">
                                    <input
                                        type="date"
                                        className="w-full px-3 py-2 border rounded-lg pl-10"
                                        value={formData.endDate}
                                        onChange={(e) => setFormData({ ...formData, endDate: e.target.value })}
                                        min={formData.startDate}
                                        max="2030-12-31"
                                    />
                                    <Calendar className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={16} />
                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium mb-1">End Time (Optional)</label>
                                <div className="relative">
                                    <input
                                        type="time"
                                        className="w-full px-3 py-2 border rounded-lg pl-10"
                                        value={formData.endTime}
                                        onChange={(e) => setFormData({ ...formData, endTime: e.target.value })}
                                    />
                                    <Clock className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={16} />
                                </div>
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1">Location</label>
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Enter a location"
                                    className="w-full px-3 py-2 border rounded-lg pl-10"
                                    value={formData.location}
                                    onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                                    required
                                />
                                <MapPin className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={16} />
                            </div>
                        </div>

                        <div className="flex justify-center">
                            <button type="submit" className="bg-gray-900 text-white px-6 py-3 rounded-lg">
                                Save Event
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddEventModal;