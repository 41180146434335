import React, { useEffect, useState } from 'react';
import { Search, Filter, Plus, ArrowUp, ArrowDown } from 'lucide-react';
import SponsorCard from './components/SponsorCard';
import SponsorModal from './components/SponsorActions';
import ConfirmationModal from '../../components/ConfirmationModal';
import Sidebar from '../../components/SideBar';

const SponsorshipManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [confirmationType, setConfirmationType] = useState('');
  const [editingSponsor, setEditingSponsor] = useState(null);
  const [sponsors, setSponsors] = useState([]);

  // New state for filtering and searching
  const [filterOption, setFilterOption] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
  const [newSponsorImage, setNewSponsorImage] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/sponsorship/get`);
      const data = await response.json();
      setSponsors(data);
    } catch (error) {
      console.error('Error fetching sponsors:', error);
      alert('Failed to fetch sponsors');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAddNew = () => {
    setEditingSponsor(null);
    setNewSponsorImage(null);
    setIsModalOpen(true);
  };

  const handleManage = (sponsor) => {
    setEditingSponsor(sponsor);
    setIsModalOpen(true);
  };

  const handleDelete = (sponsor) => {
    setEditingSponsor(sponsor);
    setConfirmationType('Delete');
    setIsConfirmationOpen(true);
  };

  const handleConfirmDelete = () => {
    setSponsors(sponsors.filter(s => s !== editingSponsor));
    fetch(`${process.env.REACT_APP_BASE_URL}/sponsorship/delete/${editingSponsor._id}`, {
      method: 'DELETE',
    });
    setIsConfirmationOpen(false);
  };

  const handleSaveSponsor = async (sponsorData) => {
    try {
      const formData = new FormData();
      formData.append('name', sponsorData.name);
      formData.append('link', sponsorData.link || '');
      formData.append('multipleRepetition', sponsorData.multipleRepetition);
      formData.append('description', sponsorData.description);

      if (newSponsorImage) {
        formData.append('image', newSponsorImage);
      } else if (sponsorData.image) {
        formData.append('image', sponsorData.image);
      }

      const url = editingSponsor 
        ? `${process.env.REACT_APP_BASE_URL}/sponsorship/edit/${editingSponsor._id}`
        : `${process.env.REACT_APP_BASE_URL}/sponsorship/add`;

      const method = editingSponsor ? 'PUT' : 'POST';

      const response = await fetch(url, {
        method,
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to save sponsor');
      }

      fetchData();
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error saving sponsor:', error.message);
      alert('Failed to save sponsor. Please try again.');
    }
  };

  // Filtering and Sorting Logic
  const filteredAndSortedSponsors = React.useMemo(() => {
    let result = [...sponsors];

    // Search filter
    if (searchTerm) {
      result = result.filter(sponsor => 
        sponsor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sponsor.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Sorting logic
    switch (filterOption) {
      case 'name':
        result.sort((a, b) => 
          sortDirection === 'asc' 
            ? a.name.localeCompare(b.name) 
            : b.name.localeCompare(a.name)
        );
        break;
      case 'recent':
        result.sort((a, b) => 
          sortDirection === 'asc'
            ? new Date(a.createdAt) - new Date(b.createdAt)
            : new Date(b.createdAt) - new Date(a.createdAt)
        );
        break;
      default:
        break;
    }

    return result;
  }, [sponsors, searchTerm, filterOption, sortDirection]);

  const handleFilterChange = (option) => {
    if (filterOption === option) {
      // Toggle sort direction if same filter is selected
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setFilterOption(option);
      setSortDirection('asc');
    }
    setIsFilterDropdownOpen(false);
  };

  const handleImageUpload = (event) => {
    setNewSponsorImage(event.target.files[0]);
  };

  return (
    <div className='flex bg-white'>
      <div className='bg-[#272938]'><Sidebar /></div>
      <div className='w-full'>
        <div className="p-6 bg-gray-50 min-h-screen max-h-screen overflow-scroll">
          <div className="max-w-6xl mx-auto mb-8 flex items-center justify-between gap-4 relative">
            <div className="flex-1 relative">
              <input 
                type="text" 
                placeholder="Search sponsors..." 
                className="w-full px-4 py-2 border rounded-md"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            
            <div className="relative">
              <button 
                onClick={() => setIsFilterDropdownOpen(!isFilterDropdownOpen)}
                className="px-4 py-2 border rounded-md hover:bg-gray-50 transition-colors flex items-center gap-2"
              >
                <Filter size={20} /> 
                FILTER 
                {filterOption && `: ${filterOption.toUpperCase()}`}
              </button>
              {isFilterDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white border rounded-md shadow-lg z-10">
                  <div 
                    onClick={() => handleFilterChange('name')} 
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex justify-between items-center"
                  >
                    A-Z {filterOption === 'name' && (sortDirection === 'asc' ? <ArrowUp size={16} /> : <ArrowDown size={16} />)}
                  </div>
                  <div 
                    onClick={() => handleFilterChange('recent')} 
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex justify-between items-center"
                  >
                    Recent Updates {filterOption === 'recent' && (sortDirection === 'asc' ? <ArrowUp size={16} /> : <ArrowDown size={16} />)}
                  </div>
                  <div 
                    onClick={() => handleFilterChange('')} 
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  >
                   Remove Filter
                  </div>
                </div>
              )}
            </div>
            
            <div onClick={handleAddNew} htmlFor="new-sponsor-image" className="cursor-pointer px-4 pr-6 py-2 bg-gray-900 text-white rounded-md hover:bg-gray-800 transition-colors flex items-center gap-2">
              <Plus size={20} /> Add
            </div>
          </div>

          <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredAndSortedSponsors.map((sponsor, index) => (
              <SponsorCard 
                key={index} 
                sponsor={sponsor} 
                onDelete={handleDelete} 
                onManage={handleManage} 
              />
            ))}
          </div>

          <SponsorModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            sponsor={editingSponsor}
            isEditing={!!editingSponsor}
            onSave={handleSaveSponsor}
            newSponsorImage={newSponsorImage}
          />

          <ConfirmationModal
            isOpen={isConfirmationOpen}
            onClose={() => setIsConfirmationOpen(false)}
            onConfirm={handleConfirmDelete}
            type={confirmationType}
          />
        </div>
      </div>
    </div>
  );
};

export default SponsorshipManagement;