import React, { useState, useEffect } from 'react';
import { Search, Filter, Plus, X, ArrowUpDown, ChevronDown } from 'lucide-react';
import ConfirmationModal from '../../components/ConfirmationModal';
import SearchCom from '../../components/SearchCom';
import Sidebar from '../../components/SideBar';

const UserFormModal = ({ isOpen, onClose, onSave, selectedUser }) => {
  const [formData, setFormData] = useState({
      name: '',
      userId: '',
      type: 'Admin',
      email: '',
      phone: ''
  });

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
      if (selectedUser) {
          setFormData({
              name: selectedUser.name || '',
              userId: selectedUser.userId || '',
              type: selectedUser.type || 'Admin',
              email: selectedUser.email || '',
              phone: selectedUser.phone || ''
          });
      } else {
          setFormData({
              name: '',
              userId: '',
              type: 'Admin',
              email: '',
              phone: ''
          });
      }
  }, [selectedUser]);

  if (!isOpen) return null;

  const handleSubmit = (e) => {
      e.preventDefault();
      onSave(formData);
  };

  const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-md mx-4">
              <div className="flex justify-end p-2">
                  <button onClick={onClose} className="p-1 hover:bg-gray-100 rounded-full">
                      <img className='w-6 mb-2' src="/assets/cancel.svg" alt="" />
                  </button>
              </div>

              <form onSubmit={handleSubmit} className="p-6 pt-0">
                  <div className="space-y-4">
                      <div className="flex items-center gap-4">
                          <label className="w-24 text-right">Name:</label>
                          <input
                              type="text"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              className="flex-1 p-2 border rounded"
                              required
                          />
                      </div>

                      <div className="flex items-center gap-4">
                          <label className="w-24 text-right">User ID:</label>
                          <input
                              type="text"
                              name="userId"
                              value={formData.userId}
                              onChange={handleChange}
                              className="flex-1 p-2 border rounded"
                              required
                          />
                      </div>

                      <div className="flex items-center gap-4">
                          <label className="w-24 text-right">Type:</label>
                          <select
                              name="type"
                              value={formData.type}
                              onChange={handleChange}
                              className="flex-1 p-2 border rounded"
                          >
                              <option>Admin</option>
                              <option>User</option>
                          </select>
                      </div>

                      <div className="flex items-center gap-4">
                          <label className="w-24 text-right">Email ID:</label>
                          <input
                              type="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              className="flex-1 p-2 border rounded"
                              required
                          />
                      </div>

                      <div className="flex items-center gap-4">
                          <label className="w-24 text-right">Phone:</label>
                          <input
                              type="tel"
                              name="phone"
                              value={formData.phone}
                              onChange={handleChange}
                              className="flex-1 p-2 border rounded"
                              required
                          />
                      </div>
                  </div>

                  <div className="flex mt-6">
                      <button
                          type="button"
                          onClick={onClose}
                          className="flex-1 py-3 text-white bg-gray-600 hover:bg-gray-700 rounded-l"
                      >
                          Forget Password
                      </button>
                      <button
                          type="submit"
                          className="flex-1 py-3 text-gray-700 bg-gray-200 hover:bg-gray-300 rounded-r"
                      >
                          Save
                      </button>
                  </div>
              </form>
          </div>
      </div>
  );
};

// User Card Component
const UserCard = ({ user, setSelectedUser, setIsFormOpen }) => (
  <div className="bg-white rounded-lg shadow p-4 space-y-2">
    <div className="flex justify-between">
      <span className="font-medium">Name:</span>
      <span>{user?.name }</span>
    </div>
    <div className="flex justify-between">
      <span className="font-medium">User ID:</span>
      <span>{user?.userId }</span>
    </div>
    <button 
      onClick={() => { 
        setSelectedUser(user);
        setIsFormOpen(true);
      }} 
      className="w-full py-2 mt-2 text-white bg-gray-800 rounded hover:bg-gray-900"
    >
      View More
    </button>
  </div>
);

const UserManagement = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  
  // New state for filtering
  const [nameSort, setNameSort] = useState('asc'); // 'asc' or 'desc'
  const [typeFilter, setTypeFilter] = useState('All'); // 'All', 'Admin', or 'User'
  const [searchQuery, setSearchQuery] = useState('');
  const [isTypeDropdownOpen, setIsTypeDropdownOpen] = useState(false);

  // Sample user data
  const [users, setUsers] = useState([])

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/usermanagement/get`);
      const data = await response.json();
      setUsers(data || []);
    } catch (error) {
      console.error('Error fetching users:', error);
      setUsers([]);
    }
  }

  useEffect(() => {
    console.log('Fetching data', process.env.REACT_APP_BASE_URL);
    // fetchData();
  }, [])

  const handleSave = async (data) => {
    setFormData(data);
    setIsFormOpen(false);
    setIsConfirmOpen(true);
  };

  const handleConfirm = async (sendPassword) => {
    console.log('Creating user:', formData, 'Send password:', sendPassword);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/usermanagement/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to add user');
      }

      const newMember = await response.json();
      console.log('After Adding', newMember);
      // fetchData();
      setIsConfirmOpen(false);
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };

  // Filtering and sorting logic
  const filteredAndSortedUsers = (users || [])
    .filter(user => {
      // Ensure user exists and has properties
      if (!user) return false;

      // Type filtering
      const typeMatch = typeFilter === 'All' || user.type === typeFilter;

      // Search query filtering (with null/undefined checks)
      const nameMatch = searchQuery === '' || 
        (user.name && user.name.toLowerCase().includes(searchQuery.toLowerCase()));

      return typeMatch && nameMatch;
    })
    .sort((a, b) => {
      // Ensure names exist before comparing
      const nameA = a.name || '';
      const nameB = b.name || '';

      if (nameSort === 'asc') {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    });

  // Type filter options
  const typeFilterOptions = ['All', 'Admin', 'User'];

  return (
    <div className='flex bg-white'>
        <div className='bg-[#272938]'><Sidebar/></div>
        <div className='w-full'>
            <div className="min-h-screen bg-gray-100 p-6 max-h-screen overflow-scroll">
                {/* Header */}
                <div className="flex justify-between items-center mb-6">
                    <div className="relative flex-1 max-w-xl">
                      <SearchCom 
                        onSearch={(query) => setSearchQuery(query)}
                      />
                    </div>
                    
                    <div className="flex items-center gap-4">
                      {/* Type Filter Dropdown */}
                      <div className="relative">
                        <button 
                          className="flex items-center gap-2 px-4 py-2 bg-white rounded-lg"
                          onClick={() => setIsTypeDropdownOpen(!isTypeDropdownOpen)}
                        >
                          <Filter size={20} />
                          {typeFilter} USERS
                          <ChevronDown size={16} />
                        </button>
                        {isTypeDropdownOpen && (
                          <div className="absolute right-0 mt-2 w-48 bg-white border rounded-lg shadow-lg z-10">
                            {typeFilterOptions.map(option => (
                              <button
                                key={option}
                                className="w-full text-left px-4 py-2 hover:bg-gray-100"
                                onClick={() => {
                                  setTypeFilter(option);
                                  setIsTypeDropdownOpen(false);
                                }}
                              >
                                {option} Users
                              </button>
                            ))}
                          </div>
                        )}
                      </div>
                      
                      {/* Name Sort Button */}
                      <button 
                        className="flex items-center gap-2 px-4 py-2 bg-white rounded-lg"
                        onClick={() => setNameSort(prev => prev === 'asc' ? 'desc' : 'asc')}
                      >
                        <ArrowUpDown size={20} />
                        {nameSort === 'asc' ? 'A to Z' : 'Z to A'}
                      </button>
                      
                      {/* Create New User Button */}
                      {/* <button
                        onClick={() => setIsFormOpen(true)}
                        className="flex items-center gap-2 px-4 py-2 bg-gray-800 text-white rounded-lg"
                      >
                        <Plus size={20} />
                        CREATE NEW USER
                      </button> */}
                    </div>
                </div>

                {/* User Grid */}
                <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {filteredAndSortedUsers.length < 0 ? (
                      filteredAndSortedUsers.map((user, index) => (
                          <UserCard 
                            key={user?.userId || index} 
                            user={user} 
                            setSelectedUser={setSelectedUser} 
                            setIsFormOpen={setIsFormOpen}
                          />
                      ))
                    ) : (
                      <div className="col-span-full text-center text-gray-500">
                        No users found
                      </div>
                    )}
                </div>

                {/* Modals */}
                <UserFormModal 
                  isOpen={isFormOpen} 
                  onClose={() => {setIsFormOpen(false); setSelectedUser(null)}} 
                  selectedUser={selectedUser} 
                  onSave={handleSave}
                />
                <ConfirmationModal 
                  isOpen={isConfirmOpen} 
                  onClose={() => setIsConfirmOpen(false)} 
                  onConfirm={handleConfirm}
                />
            </div>
        </div>
    </div>
  );
};

export default UserManagement;