// MemberModal.jsx
import React, { useState, useEffect } from 'react';
import { 
  X, 
  Phone, 
  Mail, 
  Users, 
  Edit2, 
  Calendar, 
  Clock, 
  AlertCircle,
  User,
  Briefcase,
  MapPin,
  Heart,
  CalendarCheck
} from 'lucide-react';

const ContactPopup = ({ member, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[60]">
    <div className="bg-white rounded-lg p-6 w-96">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-medium">Contact Information</h3>
        <button onClick={onClose} className="text-gray-400 hover:text-blue-500">
          <X className="w-6 h-6" />
        </button>
      </div>
      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <Mail className="w-5 h-5 text-gray-500" />
          <p>{member.email}</p>
        </div>
        <div className="flex items-center gap-2">
          <Phone className="w-5 h-5 text-gray-500" />
          <p>{member.phone}</p>
        </div>
      </div>
      <button
        onClick={onClose}
        className="mt-6 w-full px-6 py-3 bg-gray-100 text-gray-700 rounded-lg hover:bg-blue-500 hover:text-white transition-colors"
      >
        Close
      </button>
    </div>
  </div>
);

const EditFamilyForm = ({ member, dependents, onClose, onUpdate }) => {
  const [formData, setFormData] = useState({
    spouse: dependents?.spouse || { name: '', age: '' },
    children: dependents?.children || []
  });

  const handleAddChild = () => {
    setFormData(prev => ({
      ...prev,
      children: [...prev.children, { name: '', age: '' }]
    }));
  };

  const handleRemoveChild = (index) => {
    setFormData(prev => ({
      ...prev,
      children: prev.children.filter((_, i) => i !== index)
    }));
  };

  const handleChildChange = (index, field, value) => {
    setFormData(prev => ({
      ...prev,
      children: prev.children.map((child, i) => 
        i === index ? { ...child, [field]: value } : child
      )
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/membership/dependents/update/${member.userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Failed to update family information');
      }

      onUpdate && onUpdate();
      onClose();
    } catch (error) {
      console.error('Error updating family details:', error);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[60]">
      <div className="bg-white rounded-lg p-6 w-[500px] max-h-[80vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">Edit Family Information</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-blue-500">
            <X className="w-6 h-6" />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-3">
            <h4 className="font-medium text-gray-700">Spouse Details</h4>
            <div className="grid grid-cols-2 gap-3">
              <div>
                <label className="block text-sm font-medium text-gray-700">Name</label>
                <input
                  type="text"
                  value={formData.spouse.name}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    spouse: { ...prev.spouse, name: e.target.value }
                  }))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Age</label>
                <input
                  type="text"
                  value={formData.spouse.age}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    spouse: { ...prev.spouse, age: e.target.value }
                  }))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
            </div>
          </div>

          <div className="space-y-3">
            <div className="flex justify-between items-center">
              <h4 className="font-medium text-gray-700">Children</h4>
              <button
                type="button"
                onClick={handleAddChild}
                className="px-3 py-1 text-sm bg-blue-50 text-blue-600 rounded-md hover:bg-blue-100"
              >
                Add Child
              </button>
            </div>
            
            {formData.children.map((child, index) => (
              <div key={index} className="p-3 border rounded-lg space-y-3">
                <div className="flex justify-between items-center">
                  <h5 className="text-sm font-medium text-gray-600">Child {index + 1}</h5>
                  <button
                    type="button"
                    onClick={() => handleRemoveChild(index)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <X className="w-4 h-4" />
                  </button>
                </div>
                <div className="grid grid-cols-2 gap-3">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Name</label>
                    <input
                      type="text"
                      value={child.name}
                      onChange={(e) => handleChildChange(index, 'name', e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Age</label>
                    <input
                      type="text"
                      value={child.age}
                      onChange={(e) => handleChildChange(index, 'age', e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const EditContactForm = ({ member, onClose, onUpdate }) => {
  const [formData, setFormData] = useState({
    email: member.email,
    phone: member.phone
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/membership/update/${member.userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Failed to update contact information');
      }

      onUpdate && onUpdate();
      onClose();
    } catch (error) {
      console.error('Error updating contact:', error);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[60]">
      <div className="bg-white rounded-lg p-6 w-96">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">Edit Contact Information</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-blue-500">
            <X className="w-6 h-6" />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              value={formData.email}
              onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Phone</label>
            <input
              type="tel"
              value={formData.phone}
              onChange={(e) => setFormData(prev => ({ ...prev, phone: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const ValidityBadge = ({ status, daysRemaining, expiryDate }) => {
  const getBadgeStyle = () => {
    if (status === 'Active') {
      return 'bg-green-50 text-green-700 border-green-200';
    }
    return 'bg-red-50 text-red-700 border-red-200';
  };

  return (
    <div className={`flex items-center gap-2 px-4 py-2 rounded-lg border ${getBadgeStyle()}`}>
      {status === 'Active' ? (
        <Clock className="w-5 h-5" />
      ) : (
        <AlertCircle className="w-5 h-5" />
      )}
      <span className="font-medium">
        {status === 'Active' 
          ? `${daysRemaining} days remaining (Expires on ${expiryDate})` 
          : `Expired ${daysRemaining} days ago`}
      </span>
    </div>
  );
};

const MemberModal = ({ member, onClose, onUpdateStatus }) => {
  const [validityStatus, setValidityStatus] = useState({ 
    status: '', 
    daysRemaining: 0,
    expiryDate: ''
  });
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [showEditContact, setShowEditContact] = useState(false);
  const [showEditFamily, setShowEditFamily] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [dependents, setDependents] = useState(null);

 useEffect(() => {
  if (!member) return;

  const calculateValidity = async () => {
    // Create Date objects for UTC consistency
    const creationDate = new Date(member.createdAt);
    const today = new Date();
    
    // Calculate expiry date (365 days from creation)
    const expiryDate = new Date(creationDate);
    expiryDate.setDate(creationDate.getDate() + 365);
    
    // Calculate days difference
    const daysDiff = Math.floor((expiryDate - today) / (1000 * 60 * 60 * 24));
    
    // Format expiry date for display
    const formattedExpiryDate = expiryDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
    
    // Determine membership status
    const isExpired = today >= expiryDate;
    const status = isExpired ? 'Expired' : 'Active';
    const daysRemaining = isExpired ? Math.abs(daysDiff) : daysDiff;

    // Update membership status in database if expired
    if (isExpired && member.status !== 'Expired') {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/membership/update-status/${member.userId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            status: 'Expired',
            action: 'expire',
            expiryDate: expiryDate.toISOString()
          })
        });

        if (!response.ok) {
          throw new Error('Failed to update membership status');
        }

        // Notify parent component about status change
        onUpdateStatus && onUpdateStatus();
      } catch (error) {
        console.error('Error updating membership status:', error);
        setError('Failed to update membership status');
      }
    }

    // Log calculation details for debugging
    console.log('Validity Calculation:', {
      creationDate: creationDate.toISOString(),
      today: today.toISOString(),
      expiryDate: expiryDate.toISOString(),
      daysDiff,
      status,
      daysRemaining,
      isExpired
    });

    return {
      status,
      daysRemaining,
      expiryDate: formattedExpiryDate
    };
  };

  calculateValidity().then(validity => {
    setValidityStatus(validity);
  });
}, [member, onUpdateStatus]);

  const handleStatusChange = async (newStatus, action) => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/membership/update-status/${member.userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          status: newStatus,
          action: action
        })
      });

      if (!response.ok) {
        throw new Error(`Failed to ${action} membership`);
      }

      onUpdateStatus && onUpdateStatus();
      onClose();
    } catch (error) {
      console.error(`Error ${action} membership:`, error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/membership/delete/${member.userId}`, {
        method: 'DELETE'
      });

      if (!response.ok) {
        throw new Error('Failed to delete member');
      }

      onUpdateStatus && onUpdateStatus();
      onClose();
    } catch (error) {
      console.error('Error deleting member:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const renderDependentsSection = () => {
    if (!dependents) return null;

    return (
      <div className="mt-6 bg-gray-50 p-4 rounded-lg">
        <div className="flex justify-between items-center">
          <h4 className="font-semibold text-gray-700 flex items-center gap-2">
            <Users className="w-5 h-5" />
            Family Details
          </h4>
          <button
            onClick={() => setShowEditFamily(true)}
            className="p-2 text-gray-400 hover:text-blue-500 rounded-full hover:bg-gray-100"
          >
            <Edit2 className="w-4 h-4" />
          </button>
        </div>
        
        {dependents.spouse && (
          <div className="mt-3">
            <h5 className="text-sm font-medium text-gray-600 flex items-center gap-2">
              <Heart className="w-4 h-4" />
              Spouse
            </h5>
            <div className="ml-6 mt-2">
              <p className="text-sm text-gray-500">
                <span className="font-medium">Name:</span> {dependents.spouse.name}
              </p>
              <p className="text-sm text-gray-500">
                <span className="font-medium">Age:</span> {dependents.spouse.age}
              </p>
            </div>
          </div>
        )}

        {dependents.children && dependents.children.length > 0 && (
          <div className="mt-4">
            <h5 className="text-sm font-medium text-gray-600 flex items-center gap-2">
              <Users className="w-4 h-4" />
              Children
            </h5>
            <div className="ml-6 mt-2 space-y-3">
              {dependents.children.map((child, index) => (
                <div key={index} className="border-l-2 border-gray-200 pl-3">
                  <p className="text-sm text-gray-500">
                    <span className="font-medium">Name:</span> {child.name}
                  </p>
                  <p className="text-sm text-gray-500">
                    <span className="font-medium">Age:</span> {child.age}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  if (!member) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-y-auto">
      <div className="text-center sm:block sm:p-0 w-full max-w-4xl mx-4">
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full">
          <div className="bg-white px-6 pt-6 pb-6">
            <div className="flex justify-between items-start mb-6">
              <h3 className="text-xl font-medium text-gray-900">Member Details</h3>
              <button
                onClick={onClose}
                className="text-gray-400 hover:text-blue-500 p-2 rounded-full hover:bg-gray-100"
              >
                <X className="w-6 h-6" />
              </button>
            </div>
            
            {error && (
              <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg flex items-center gap-2">
                <AlertCircle className="w-5 h-5" />
                {error}
              </div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-6">
                <div className="bg-gray-50 p-4 rounded-lg">
                  <h4 className="font-semibold text-gray-700 flex items-center gap-2 mb-3">
                    <User className="w-5 h-5" />
                    General Information
                  </h4>
                  <div className="space-y-2">
                    <p className="text-sm text-gray-500"><strong>Request ID:</strong> {member.requestId}</p>
                    <p className="text-sm text-gray-500"><strong>User ID:</strong> {member.userId}</p>
                    <p className="text-sm text-gray-500"><strong>Name:</strong> {`${member.firstname} ${member.lastname}`}</p>
                    <p className="text-sm text-gray-500 flex items-center gap-2">
                      <Mail className="w-4 h-4" />
                      {member.email}
                    </p>
                    <p className="text-sm text-gray-500 flex items-center gap-2">
                      <Phone className="w-4 h-4" />
                      {member.phone}
                    </p>
                    <p className="text-sm text-gray-500 flex items-center gap-2">
                      <MapPin className="w-4 h-4" />
                      {member.address}
                    </p>
                  </div>
                </div>

                <div className="bg-gray-50 p-4 rounded-lg">
                  <h4 className="font-semibold text-gray-700 flex items-center gap-2 mb-3">
                    <Calendar className="w-5 h-5" />
                    Plan Details
                  </h4>
                  <div className="space-y-3">
                    <p className="text-sm text-gray-500">
                      <strong>Plan Type:</strong> {member.membershipName}
                    </p>
                    <ValidityBadge 
                      status={validityStatus.status}
                      daysRemaining={validityStatus.daysRemaining}
                    />
                    <p className="text-sm text-gray-500">
                      <strong>Member Since:</strong> {new Date(member.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              </div>

              <div className="space-y-6">
                <div className="bg-gray-50 p-4 rounded-lg">
                  <h4 className="font-semibold text-gray-700 flex items-center gap-2 mb-3">
                    <Briefcase className="w-5 h-5" />
                    Personal Details
                  </h4>
                  <div className="space-y-2">
                    <p className="text-sm text-gray-500"><strong>Profession:</strong> {member.profession}</p>
                    <p className="text-sm text-gray-500"><strong>Date of Birth:</strong> {member.doB}</p>
                    <p className="text-sm text-gray-500"><strong>Gender:</strong> {member.gender}</p>
                    <p className="text-sm text-gray-500"><strong>Marital Status:</strong> {member.maritalStatus}</p>
                  </div>
                </div>

                {renderDependentsSection()}
              </div>
            </div>
          </div>
          
          <div className="bg-gray-50 px-6 py-4">
            <div className="flex justify-end space-x-3">
              {member.status === 'Pending' && (
                <>
                  <button
                    onClick={() => handleStatusChange('Denied', 'deny')}
                    disabled={loading}
                    className="px-4 py-2 bg-red-50 text-red-700 rounded-lg hover:bg-red-100 transition-colors disabled:opacity-50"
                  >
                    Deny Application
                  </button>
                  <button
                    onClick={() => setShowContactPopup(true)}
                    disabled={loading}
                    className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors disabled:opacity-50"
                  >
                    Contact
                  </button>
                  <button
                    onClick={() => handleStatusChange('Current', 'accept')}
                    disabled={loading}
                    className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50"
                  >
                    Accept Application
                  </button>
                </>
              )}

              {member.status === 'Current' && (
                <>
                  <button
                    onClick={() => handleStatusChange('Closed', 'close')}
                    disabled={loading}
                    className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors disabled:opacity-50"
                  >
                    Close Member
                  </button>
                  <button
                    onClick={() => handleStatusChange('Paused', 'pause')}
                    disabled={loading}
                    className="px-4 py-2 bg-yellow-50 text-yellow-700 rounded-lg hover:bg-yellow-100 transition-colors disabled:opacity-50"
                  >
                    Pause Member
                  </button>
                  <button
                    onClick={() => setShowEditContact(true)}
                    disabled={loading}
                    className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50"
                  >
                    Manage
                  </button>
                </>
              )}

              {(member.status === 'Expired' || member.status === 'Paused') && (
                <>
                  <button
                    onClick={() => handleStatusChange('Current', 'activate')}
                    disabled={loading}
                    className="px-4 py-2 bg-green-50 text-green-700 rounded-lg hover:bg-green-100 transition-colors disabled:opacity-50"
                  >
                    Activate Member
                  </button>
                  <button
                    onClick={() => setShowEditContact(true)}
                    disabled={loading}
                    className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors disabled:opacity-50"
                  >
                    Manage
                  </button>
                  <button
                    onClick={handleDelete}
                    disabled={loading}
                    className="px-4 py-2 bg-red-50 text-red-700 rounded-lg hover:bg-red-100 transition-colors disabled:opacity-50"
                  >
                    Delete Member
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {showContactPopup && (
        <ContactPopup 
          member={member} 
          onClose={() => setShowContactPopup(false)} 
        />
      )}

      {showEditContact && (
        <EditContactForm 
          member={member} 
          onClose={() => setShowEditContact(false)}
          onUpdate={onUpdateStatus}
        />
      )}

      {showEditFamily && (
        <EditFamilyForm 
          member={member}
          dependents={dependents}
          onClose={() => setShowEditFamily(false)}
          onUpdate={() => {
            fetch(`${process.env.REACT_APP_BASE_URL}/membership/dependents/${member.userId}`)
              .then(res => res.json())
              .then(data => setDependents(data))
              .catch(err => console.error('Error refreshing dependents:', err));
          }}
        />
      )}
    </div>
  );
};

export default MemberModal;