import React, { useEffect } from 'react';

const MembershipNotifications = () => {
  useEffect(() => {
    // Check for members nearing expiration or expired
    const checkMembershipStatus = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/membership/getall`);
        if (!response.ok) throw new Error('Failed to fetch members');
        
        const members = await response.json();
        const today = new Date();

        for (const member of members) {
          if (!member.createdAt) continue;

          const creationDate = new Date(member.createdAt);
          const expiryDate = new Date(creationDate);
          expiryDate.setDate(creationDate.getDate() + 365);

          const oneDayBefore = new Date(expiryDate);
          oneDayBefore.setDate(expiryDate.getDate() - 1);

          const todayStr = today.toDateString();
          const oneDayBeforeStr = oneDayBefore.toDateString();
          const expiryDateStr = expiryDate.toDateString();

          // Send notification one day before expiry
          if (todayStr === oneDayBeforeStr) {
            await sendExpiryEmail(member, false);
          }

          // Send notification on expiry day
          if (todayStr === expiryDateStr) {
            await sendExpiryEmail(member, true);
          }
        }
      } catch (err) {
        console.error('Error checking membership status:', err);
      }
    };

    const sendExpiryEmail = async (member, isExpired) => {
      try {
        await fetch(`${process.env.REACT_APP_BASE_URL}/membership/send-expiry-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: member.email,
            membershipName: member.membershipName,
            isExpired: isExpired
          })
        });
      } catch (err) {
        console.error('Failed to send notification email:', err);
      }
    };

    // Check initially
    checkMembershipStatus();

    // Set up daily check at midnight
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    
    const timeUntilMidnight = tomorrow - now;
    
    // Initial timeout to start the daily checks at midnight
    const initialTimeout = setTimeout(() => {
      checkMembershipStatus();
      // Set up daily interval after the first midnight check
      const dailyInterval = setInterval(checkMembershipStatus, 24 * 60 * 60 * 1000);
      return () => clearInterval(dailyInterval);
    }, timeUntilMidnight);

    return () => clearTimeout(initialTimeout);
  }, []);

  // No UI rendering needed
  return null;
};

export default MembershipNotifications;