import React, { useState, useEffect } from 'react';
import TicketCard from './TicketCard';
import SearchCom from '../../../components/SearchCom';
import EditTicketModal from './EditTicketModal';

const SupportDeskManagerCon = () => {
  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [activeTab, setActiveTab] = useState('New');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);    
  const [selectedTicket, setSelectedTicket] = useState(null);


  const fetchTickets = async () => {
    try {
      setIsLoading(true);      
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/supportdesk/get`);
      const data = await response.json();
      console.log('Received data:', data);
      setTickets(data);
      setError(null);
    } catch (err) {
      console.error('Fetch error:', err);
      setError(err.message || 'Failed to load tickets. Please try again later.');
      setTickets([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  useEffect(() => {
    setFilteredTickets(tickets.filter(t => t.status === activeTab || (activeTab === 'completed' && t.status === 'cancelled')));
  }, [activeTab, tickets]); 

  const onMarkAsCompleted = async(ticketId) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/supportdesk/edit/${ticketId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status: 'completed' }),
    })
    .then(response => {response.json();fetchTickets();})
    .then(data => console.log(data))
    .catch(err => console.error(err));
  }

  const onCancelTicket = async(ticketId) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/supportdesk/edit/${ticketId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status: 'cancelled' }),
    })
    .then(response => {response.json();fetchTickets();})
    .then(data => console.log(data))
    .catch(err => console.error(err));
  }

  const handleEdit = (ticket) => {
    
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-lg">Loading tickets...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-lg text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen max-h-screen w-full px-10 overflow-scroll">
      <div className="py-5 mx-auto flex items-center justify-between gap-4">
        <div className="w-[70%] flex gap-6">
          <SearchCom />
        </div>
        <div>
          <img src="/assets/mask.svg" alt="Support Desk Logo" className="w-16 h-16" />
        </div>
      </div>
      <div className="p-4 max-w-7xl mx-auto">
        <div className="w-[90%] mx-auto flex gap-4 mb-8">
          <button
            className={`flex-1 px-4 py-2 ${activeTab === 'New' ? 'border-b-2 border-[rgb(40,45,70)] text-[rgb(40,45,70)]' : 'text-gray-500'}`}
            onClick={() => setActiveTab('New')}
          >
            Live
          </button>
          <button
            className={`flex-1 px-4 py-2 ${activeTab === 'completed' ? 'border-b-2 border-[rgb(40,45,70)] text-[rgb(40,45,70)]' : 'text-gray-500'}`}
            onClick={() => setActiveTab('completed')}
          >
            Completed
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {filteredTickets.map(ticket => (
              <TicketCard
                key={ticket._id}
                ticket={ticket}
                setSelectedTicket={setSelectedTicket}
                onMarkAsCompleted={onMarkAsCompleted}
                onEdit={handleEdit}
              />
            ))}
        </div>
        
        <EditTicketModal
            ticket={selectedTicket} 
            onClose={()=>setSelectedTicket(null)} 
            // onSave={handleSave} 
            // onChange={handleChange}
            onMarkAsCompleted={onMarkAsCompleted}
            onCancelTicket={onCancelTicket}
        />

      </div>
    </div>
  );
};

export default SupportDeskManagerCon;