import React from 'react';

const TeamMemberCard = ({ member, onEdit }) => (

    <div className="bg-white p-6 rounded-xl shadow-lg">
        <div className="flex flex-col items-center">
            <div className="w-24 h-24 mb-4">
                {
                    member.image instanceof File ? (
                        <img src={URL.createObjectURL(member.image)} alt={member.name} className="w-full h-full rounded-full object-cover border-2 border-dashed border-gray-200" />
                    ) : (
                        <img src={ `${process.env.REACT_APP_BASE_URL}/uploads/${member.image}` || "/api/placeholder/96/96"} alt={member.name} className="w-full h-full rounded-full object-cover border-2 border-dashed border-gray-200" />
                    )
                }
            </div>
            <h3 className="font-medium text-lg">{member.name}</h3>
            <p className="text-gray-600 mb-2">{member.designation}</p>
            {/* <p className="text-gray-500 text-sm text-center mb-4">{member.description}</p> */}
            <button onClick={() => onEdit(member)} className="px-6 py-2 border-2 rounded-full hover:bg-gray-50 transition-colors flex items-center gap-1">
                Edit Member
            </button>
        </div>
    </div>
);

export default TeamMemberCard;
