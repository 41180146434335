import React, { useState, useEffect } from "react";
import {
  Calendar,
  Users,
  Gift,
  Image,
  HeadphonesIcon,
  UserPlus,
  FileText,
  Settings,
  LogOut,
  BookOpen,
  User,
} from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";

const Sidebar = () => {
  const [activeItem, setActiveItem] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const url = location.pathname;
    const urlSegment = url.split("/").pop(); // Extracts the last part of the URL

    // Find the matching menu item based on the URL segment
    const matchedItem = menuItems.find(
      (item) => urlSegment === item.title.replace(/\s+/g, "").toLowerCase()
    );

    if (matchedItem) {
      setActiveItem(matchedItem.title);
    }
  }, [location.pathname]);

  const menuItems = [
    { title: "Event Management", icon: Calendar },
    { title: "Team Management", icon: Users },
    { title: "Sponsorship", icon: Gift },
    { title: "Gallery", icon: Image },
    { title: "Support Desk", icon: HeadphonesIcon },
    { title: "Membership Management", icon: UserPlus },
    // { title: 'Story Board', icon: BookOpen },
    { title: "Blog Management", icon: FileText },
    { title: "User Management", icon: User },
    // { title: 'Settings', icon: Settings },
    { title: "Logout", icon: LogOut },
  ];

  return (
    <div className="flex min-h-screen bg-gray-900 w-full min-w-[18em] max-w-[18em]">
      <div className="text-white p-4 flex flex-col items-center justify-center">
        <div className="mb-8">
          <div className="flex items-center justify-center mb-4">
            <div className="bg-orange-500 rounded-full p-3">
              <img src="/assets/logo.svg" alt="Logo" className="w-10 h-10" />
            </div>
          </div>
          <h1 className="text-xl font-medium text-center text-white">
            Dashboard
          </h1>
        </div>

        <nav>
          {menuItems.map((item) => {
            const Icon = item.icon;
            return (
              <button
                key={item.title}
                onClick={() => {
                  if (item.title === "Logout") {
                    // Redirect to "/"
                    navigate("/");
                  } else {
                    setActiveItem(item.title);
                    navigate(
                      `/admin/${item.title.replace(/\s+/g, "").toLowerCase()}`
                    );
                  }
                }}
                className={`
                    w-full flex items-center gap-3 px-4 py-3 my-1 rounded-lg
                    transition-all duration-200 ease-in-out
                    ${
                      activeItem === item.title
                        ? "bg-white text-gray-900"
                        : "text-gray-300 hover:bg-gray-800 hover:text-white"
                    }
                  `}
                >
                <Icon size={20} />
                <span className="text-sm">{item.title}</span>
              </button>
            );
          })}
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
