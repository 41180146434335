import React, { useState, useEffect, useMemo } from 'react';
import MemberTable from './MemberTable';
import MemberModal from './MemberModal';
import SearchCom from '../../../components/SearchCom';

const MemberDashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterPlanType, setFilterPlanType] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const planTypes = [
    'Silver-Family', 
    'Gold-Family', 
    'Silver-Student', 
    'Gold-Student', 
    'Silver-Single'
  ];

  const sortOptions = [
    { value: 'userId', label: 'User ID' },
    { value: 'firstname', label: 'Name (A-Z)' },
    { value: 'createdAt', label: 'Member Since' }
  ];

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/membership/getall`);
        
        if (!response.ok) {
          throw new Error('Failed to fetch members');
        }
        
        const data = await response.json();
        setMembers(data);
        setError(null);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching members:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchMembers();
  }, []);

  const filteredAndSortedMembers = useMemo(() => {
    if (!Array.isArray(members)) return [];

    let result = [...members];

    // Filter by plan type
    if (filterPlanType) {
      result = result.filter(member => 
        member.membershipName?.toLowerCase().includes(filterPlanType.toLowerCase())
      );
    }

    // Search functionality
    if (searchTerm) {
      const searchWords = searchTerm.toLowerCase().trim().split(/\s+/);
      result = result.filter(member => {
        const fullName = `${member.firstname || ''} ${member.lastname || ''}`.toLowerCase();
        const userId = (member.userId || '').toString().toLowerCase();
        const requestId = (member.requestId || '').toString().toLowerCase();
        const membershipName = (member.membershipName || '').toString().toLowerCase();
        
        return searchWords.every(word => 
          fullName.includes(word) ||
          userId.includes(word) ||
          requestId.includes(word) ||
          membershipName.includes(word)
        );
      });
    }

    // Sorting
    if (sortBy) {
      result.sort((a, b) => {
        if (sortBy === 'firstname') {
          const nameA = `${a.firstname || ''} ${a.lastname || ''}`.toLowerCase();
          const nameB = `${b.firstname || ''} ${b.lastname || ''}`.toLowerCase();
          return nameA.localeCompare(nameB);
        }
        if (sortBy === 'createdAt') {
          const dateA = new Date(a.createdAt || 0);
          const dateB = new Date(b.createdAt || 0);
          return dateB - dateA; // Most recent first
        }
        // Default string comparison for other fields
        const valueA = (a[sortBy] || '').toString().toLowerCase();
        const valueB = (b[sortBy] || '').toString().toLowerCase();
        return valueA.localeCompare(valueB);
      });
    }

    return result;
  }, [members, filterPlanType, searchTerm, sortBy]);

  const handleViewMore = (member) => {
    setSelectedMember(member);
    setIsModalOpen(true);
  };

  const resetFilters = () => {
    setFilterPlanType('');
    setSearchTerm('');
    setSortBy('');
    document.getElementById('planTypeDropdown')?.classList.add('hidden');
    document.getElementById('sortDropdown')?.classList.add('hidden');
  };

  const handleDropdownClick = (dropdownId) => {
    const dropdowns = ['planTypeDropdown', 'sortDropdown'];
    dropdowns.forEach(id => {
      if (id === dropdownId) {
        document.getElementById(id)?.classList.toggle('hidden');
      } else {
        document.getElementById(id)?.classList.add('hidden');
      }
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdownContainers = document.querySelectorAll('.dropdown-container');
      let clickedInsideDropdown = false;
      
      dropdownContainers.forEach(container => {
        if (container.contains(event.target)) {
          clickedInsideDropdown = true;
        }
      });

      if (!clickedInsideDropdown) {
        document.getElementById('planTypeDropdown')?.classList.add('hidden');
        document.getElementById('sortDropdown')?.classList.add('hidden');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen w-full px-10 flex items-center justify-center">
        <p className="text-gray-500">Loading members...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen w-full px-10 flex items-center justify-center">
        <div className="text-center">
          <p className="text-red-500 mb-4">Error: {error}</p>
          <button 
            onClick={() => window.location.reload()}
            className="bg-[rgb(40,45,70)] text-white px-4 py-2 rounded-lg hover:bg-[rgb(50,55,80)]"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen max-h-screen w-full px-10 overflow-scroll">
      {/* <div className="py-5  gap-4">
       
        <div className="flex justify-end">
          <img src="/assets/mask.svg" alt="Logo" className="h-8 w-auto" />
        </div>
      </div> */}

      <div className="p-6 max-w-7xl mx-auto">
        <MemberTable 
          members={filteredAndSortedMembers}
          onViewMore={handleViewMore}
        />
        {isModalOpen && selectedMember && (
          <MemberModal 
            member={selectedMember}
            onClose={() => {
              setIsModalOpen(false);
              setSelectedMember(null);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default MemberDashboard;