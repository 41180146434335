import React, { useState } from 'react';
import { Calendar, MoveLeftIcon, Upload, Trash } from 'lucide-react';

const FolderCreation = ({ newFolder, setNewFolder, handleCreateFolder, isEditing, setShowCreateFolder, setSelectedFolder, setShowDeleteConfirm, deleteImageNames, setdeleteImageNames }) => {
  const [imagePreviews, setImagePreviews] = useState([]);
  const [data,setaData] = useState([]);

  console.log(newFolder);
  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const handleImageDelete = (image,index) => {
    console.log(image,index);
    setdeleteImageNames([...deleteImageNames,image]);
    const updatedImages = [...newFolder.images];
    updatedImages.splice(index, 1);
    setNewFolder({ ...newFolder, images: updatedImages });
  };
  
  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
    console.log(files);
    
    const updatedFolder = {
      ...newFolder,
      images: [...newFolder.images, ...files.map((file, index) => ({
        rawFiles: file,  
      }))],
    };
    console.log(updatedFolder,"File");
    setNewFolder(updatedFolder);
    setaData(updatedFolder);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <div className="mb-8">
        <button
          onClick={() => setShowCreateFolder(false)}
          className="bg-gray-200 text-gray-700 px-4 py-2 rounded-full hover:bg-gray-300 transition-colors flex items-center gap-2"
        >
          <MoveLeftIcon size={14} />
          Back
        </button>
      </div>

      <div className="space-y-4 px-2">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Folder Name
          </label>
          <input
            type="text"
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[rgb(40,45,75)]"
            value={newFolder.name}
            onChange={(e) => setNewFolder({ ...newFolder, name: e.target.value })}
            placeholder="Ex: Pongal Celebration"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Date/Month
          </label>
          <div className="relative">
            <input
              type="date"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[rgb(40,45,75)]"
              value={newFolder.date}
              onChange={(e) => setNewFolder({ ...newFolder, date: e.target.value })}
            />
            <Calendar className="absolute right-3 top-2.5 text-gray-400" size={20} />
          </div>
        </div>

        {/* Image selection section */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mt-4 mb-2">
            Images
          </label>
          <div className="flex flex-wrap gap-2">
            {/* Already */}
            {newFolder.images.length > 0 &&
                newFolder.images.map((image, index) => (
                <div key={index} className="w-32 h-32 bg-gray-100 rounded-lg overflow-hidden relative group">

                  {
                    image.rawFiles instanceof File ? (
                    <img src={URL.createObjectURL(image.rawFiles)} alt="Preview1" className="w-full h-full object-cover" />
                  ) : (
                      <img src={`${process.env.REACT_APP_BASE_URL}/uploads/${image}`} alt="Preview2" className="w-full h-full object-cover" />
                  )
                  }

                  {/* Delete Icon */}
                  <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                    <button
                      onClick={() => handleImageDelete(image,index)}
                      className="bg-white/40 p-2 rounded-full text-gray-500 hover:text-red-500"
                    >
                      <Trash size={16} />
                    </button>
                  </div>
                </div>
            ))}
            <div className="w-32 h-32 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center cursor-pointer hover:border-gray-400 transition-colors">
            <input
                type="file"
                multiple
                accept="image/*"
                onChange={handleImageChange}
                className="hidden"
                id="image-upload"
            />
            <label htmlFor="image-upload" className="w-full h-full flex items-center justify-center">
                <div className="text-center">
                <Upload className="mx-auto text-gray-400 mb-2" size={24} />
                <span className="text-sm text-gray-500">Choose Images</span>
                </div>
            </label>
            </div>
          </div>
          </div>

        {/* If editing, show delete button */}
        <div className="flex w-full items-center justify-between">
          {isEditing && (
            <div>
              <button
                onClick={() => {
                  setSelectedFolder(newFolder);
                  setShowDeleteConfirm(true);
                }}
                className="w-fit px-6 py-2 bg-red-500 text-white rounded-full hover:bg-red-600 mt-4"
              >
                Delete Folder
              </button>
            </div>
          )}

          <div className="flex">
            <button
              className="w-fit px-6 py-2 bg-gray-900 text-white rounded-full hover:bg-gray-800 mt-4"
              onClick={handleCreateFolder}
            >
              {isEditing ? 'Update Folder' : 'Add Folder'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FolderCreation;
