import React, { useState } from 'react';
import { Mail, Phone } from 'lucide-react';

const TicketCard = ({ ticket, onMarkAsCompleted, onEdit, setSelectedTicket }) => {

  const onHandleEdit = (ticket) => {
    setSelectedTicket(ticket);
    onEdit(ticket);
    console.log("Editing ticket:", ticket);
  }

  const HandleComplete = () => {
    onMarkAsCompleted(ticket._id);
  }

  return (
    <div className="bg-white rounded-lg shadow-sm border p-4">
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <span className="text-sm font-medium">#{ticket._id}</span>
          <span
            className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ${
              ticket.status === 'cancelled'
                ? 'bg-red-100 text-red-800'
                : ticket.status === 'New'
                ? 'bg-blue-100 text-blue-800'
                : 'bg-green-100 text-green-800'
            }`}
          > 
            { ticket.status === 'cancelled' ? 'Cancelled' : ticket.status === 'New' ? 'Live' : 'Completed' }
          </span>
        </div>
        <h3 className="font-medium">{ticket.name || ticket.title}</h3>
        <div className="space-y-2 text-sm text-gray-600">
          {ticket.email && (
            <div className="flex items-center gap-2">
              <Mail className="w-4 h-4" />
              {ticket.email}
            </div>
          )}
          {ticket.phone && (
            <div className="flex items-center gap-2">
              <Phone className="w-4 h-4" />
              {ticket.phone}
            </div>
          )}
        </div>
        <div className="flex gap-2">
          <button
            className="flex items-center justify-center px-4 py-2 border rounded-md hover:bg-gray-50"
            onClick={() => onHandleEdit(ticket)}
          >
            {ticket.status === 'New' ? 'Manage' : 'View'}
          </button>
          {ticket.status === 'New' && (
            <button
              className="flex-1 px-4 py-2 bg-[rgb(40,45,75)] text-white rounded-md"
              onClick={HandleComplete}
            >
              Mark as Completed
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TicketCard;