import React from 'react'
import Sidebar from '../../components/SideBar'
import MemberDashboard from './components/MemberDashboard'
import MembershipNotifications from './components/MembershipNotifications'

function Membershipmanagement() {
  return (
    <div className='flex bg-white'>
      <div className='bg-[#272938]'><Sidebar /></div>
      <MembershipNotifications />
      <div className='w-full'><MemberDashboard/></div>
    </div>
  )
}

export default Membershipmanagement
