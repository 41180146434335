import React from "react";
import { Plus } from "lucide-react";

const FolderGrid = ({ folders, onEdit }) => {
  console.log(folders);
  return (
    <div className="flex flex-wrap gap-8">
      {folders.map((folder) => (
        <div key={folder._id} className="bg-white rounded-lg shadow-sm overflow-hidden">
          <div className="w-40 h-40 bg-gray-100">
            {folder.images.length > 0 ? (
              folder.images[0] instanceof File ? (
                <img
                  src={URL.createObjectURL(folder.images[0])}
                  alt={folder.name}
                  className="w-full h-full object-cover"
                />
              ) : (
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/uploads/${folder.images[0]}`}
                  alt={folder.name}
                  className="w-full h-full object-cover"
                />
              )
              // <img
              //   src={
              //     folder.images[0]?.url || URL.createObjectURL(folder.images[0])
              //   }
              //   alt={folder.name}
              //   className="w-full h-full object-cover"
              // />
            ) : (
              <div className="w-full h-full flex items-center justify-center">
                <Plus className="text-gray-400" size={24} />
              </div>
            )}
          </div>
          <div className="py-4">
            <h3 className="font-medium text-gray-900">{folder.name}</h3>
            <p className="text-sm text-gray-500">{new Date(folder.date).toLocaleDateString()}</p>
            <div className="flex gap-2 mt-2">
              <button
                onClick={() => onEdit(folder)}
                className="w-full px-4 py-2 border rounded-md hover:bg-gray-200"
              >
                Manage Folder
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FolderGrid;
